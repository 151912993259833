import React,{ useContext } from "react";
import style from "./style.less";
import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { Typography,Toast } from "react-vant";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PersistContext } from "@/data/PersistProvider";
import { LanguageContext } from "@/data/LanguageProvider";
import { FunctionalConfig, LocalConfig } from "@/config";

const WithdrawControl = () => {
  const { getPersist } = useContext(PersistContext);
  const { langText } = useContext(LanguageContext);
  const bgSettings = getPersist("bgSettings");
  // let whatsApp = "85253053474";
  // if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.whatsapp) {
  //   whatsApp = bgSettings.settings.client_config.whatsapp;
  // }
  let contactType = "WhatsApp"
  let contactNumber = "85253053474"
  let contactCopy = ""
  if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.contactType) {
    contactType = bgSettings.settings.client_config.contactType;
  }
  if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.contactNumber) {
    contactNumber = bgSettings.settings.client_config.contactNumber;
  }
  if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.contactCopy) {
    contactCopy = bgSettings.settings.client_config.contactCopy;
  }else{
    contactCopy = contactNumber
  }
  return (
    <div class={style.withdraw_control_container}>
      <HomeHeaderOther title={"Risk control tips"} />
      <div class={style.withdraw_control_top}>
        <p>Your withdrawal request has been submitted. </p>
        <p>Our staff is currently processing it, and it is expected to be completed within 15 minutes to 24 hours.</p>
        <p>We appreciate your patience and understanding during this time.</p>
      </div>
      <div class={style.withdraw_control_intro}>
        Use {contactType}: 
                {FunctionalConfig.CustomerContactClickable ? (
                  <a
                    href={contactCopy}
                    style={{ textDecoration: "underline", marginLeft: "0.5rem" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {contactNumber}
                  </a>
                ) : (
                  contactNumber
                )}
                <CopyToClipboard
                  text={contactCopy}
                  onCopy={() => {
                    Toast.success({
                      message: "Copy Successfully",
                    });
                  }}
                >
                  <img
                    style={{ width: "1rem", height: "1rem", margin: "0 .7rem 0 .2rem" }}
                    src={require("@/assets/icons/copy.png").default}
                  />
                </CopyToClipboard>
        to contact customer service
      </div>
      <div class={style.withdraw_control_footer}>
        <img src={require("@/assets/images/home/18_plus.png").default} />
        <span>© {LocalConfig.gameName} {langText("common_tips_1")}</span>
      </div>
    </div>
  );
};

export default WithdrawControl;
