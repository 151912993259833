const payments = [
  // {
  //   text: "channel W",//wkplusspay
  //   enable: true,
  //   merchantId: 34,
  //   type: "BANKCARD",
  //   img: "",
  //   minDepositAmount: 5000,
  //   maxDepositAmount: 5000000,
  //   minWithdrawAmount: 30000,
  //   maxWithdrawAmount: 5000000,
  //   checkPrecedence: (phone) => {
  //     return false;
  //   },
  // },
  {
    text: "channel W", //"MTN",
    enable: true,
    type: "31003", //"MTNZM",
    merchantId: 34,
    img: "../assets/images/home/MTN.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 760 && phoneHeader <= 769) ||
        (phoneHeader >= 960 && phoneHeader <= 969)
      ) {
        return true;
      }
      return false;
    },
  },
  {
    text: "channel W", //"Airtel",
    enable: true,
    merchantId: 34,
    type: "31002",//"AIRTELZM",
    img: "../assets/images/home/airtel_money.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 770 && phoneHeader <= 779) ||
        (phoneHeader >= 970 && phoneHeader <= 979)
      ) {
        return true;
      }
      return false;
    },
  },
  {
    text: "channel W", //"Zamtel",
    enable: true,
    merchantId: 34,
    type: "31004",//"ZAMTEL",
    img: "../assets/images/home/zamtel.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 750 && phoneHeader <= 759) ||
        (phoneHeader >= 950 && phoneHeader <= 959) ||
        (phoneHeader >= 211 && phoneHeader <= 218)
      ) {
        return true;
      }
      return false;
    },
  },

  {
    text: "channel A", //"MTN",
    enable: true,
    type: "MTNZM",
    merchantId: 15,
    img: "../assets/images/home/MTN.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 760 && phoneHeader <= 769) ||
        (phoneHeader >= 960 && phoneHeader <= 969)
      ) {
        return true;
      }
      return false;
    },
  },
  {
    text: "channel A", //"Airtel",
    enable: true,
    merchantId: 15,
    type: "AIRTELZM",
    img: "../assets/images/home/airtel_money.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 770 && phoneHeader <= 779) ||
        (phoneHeader >= 970 && phoneHeader <= 979)
      ) {
        return true;
      }
      return false;
    },
  },
  {
    text: "channel A", //"Zamtel",
    enable: true,
    merchantId: 15,
    type: "ZAMTEL",
    img: "../assets/images/home/zamtel.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 750 && phoneHeader <= 759) ||
        (phoneHeader >= 950 && phoneHeader <= 959) ||
        (phoneHeader >= 211 && phoneHeader <= 218)
      ) {
        return true;
      }
      return false;
    },
  },



];
export default {
  payments: payments.filter((payment) => payment.enable),
};
