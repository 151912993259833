import React from "react";
import style from "./style.less";
import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { LocationInfo } from "@/data/LocationInfo";
import { LocalConfig } from "@/config";


const Termcond = () => {
  return (
    <div class={style.page_container}>
      <HomeHeaderOther />
      <div class={style.help_content}>
        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>A. INTRODUCTION</h2>
          <ul>
            <li>
              1. By using and/or visiting any section (including sub-domains) of the {LocalConfig.gameName} website or 	any other websites or applications that we own (the
              "Website") and/or registering on the Website, you agree to be bound by (i) These Terms and 	Conditions; (it) our privacy policy (il) our cookies and (iv) the rules applicable to our betting 	or gaming products as further referenced at paragraph 2 below (together the "Terms"), and 	are deemed to have accepted and understood all the Terms.
              Please read the Terms carefully and if you do not accept the Terms, do not use the Website. 	The Terms shall also apply to all telephone betting and betting or gaming via mobile devices 	including downloadable applications to a mobile device (as if references to your use of the 	Website were references to your use of our telephone betting and/or mobile devices betting 	facilities).
            </li>
            <li >
              2. Where you play any game, or place a bet or wager, using the Website, you accept and 	agree to be bound by, the Rules which apply to the applicable products available on the 	Website from time to time. The Rules can be found under the Help tab of the applicable 	section of the Website, or at selected outlets.
            </li>
            <li >
              3. We may need to change the Terms from time to time for a number of reasons (including 	complying with applicable laws and regulations, and regulatory requirements). Any minor or 	insubstantial changes may be made at any time and you are advised to review the Terms on 	a regular basis. The most up to date Terms will be available on the Website. All major 	changes will be notified to you in advance of such changes taking effect. If any change is 	unacceptable to you, you should cease using the Website and/or close your account. If, 	however, you continue to use the Website after the date on which the changes to the Terms 	come into effect, you will be deemed to have accepted those changes.
            </li>
            <li >
              4. Reference to "you", "your" or the "customer' is reference to any person using the Website 	or the services of {LocalConfig.gameName} and/or any registered customer of {LocalConfig.gameName}.
            </li>
            <li>
              5. As you are aware, the right to access and/or use the Website (including any or all of the 	products offered via the Website) may be illegal in certain countries (including, for example, 	the USA). You are responsible for determining whether you're accessing and/or use of the 	Website is compliant with applicable laws in your jurisdiction and you warrant to us that 	gambling is not illegal in the territory where you reside.
            </li>
            <li>
              6. {LocalConfig.gameName} is committed to providing excellent customer service. As part of that 	commitment, {LocalConfig.gameName} is committed to supporting responsible gambling. Although 	{LocalConfig.gameName} will use its reasonable endeavours to enforce its responsible gambling policies, 	{LocalConfig.gameName} does not accept any responsibility or liability if you nevertheless continue 	gambling and/or seek to use the Website with the intention of deliberately avoiding the 	relevant measures in place and/or {LocalConfig.gameName} is unable to enforce its measures/policies for 	reasons outside of {LocalConfig.gameName} 's reasonable control.
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>B. YOUR {LocalConfig.gameName} ACCOUNT</h2>
          <h2 class={style.help_content_h2}>1. Application</h2>
          <ul>
            <li>1.1 All applicants must be over 18 years of age and of legal capacity to place a bet/wager or 	register with {LocalConfig.gameName} . {LocalConfig.gameName} reserves the right to ask for proof of age from any 	customer and suspend their account until satisfactory documentation is provided.
              {LocalConfig.gameName} takes its responsibilities in respect of under age and responsible gambling very 	seriously.
            </li>
            <li >1.2 All information supplied when registering with the site MUST be accurate and complete 	in all respects. Where this is not the case, we reserve the right to suspend the account and 	treat any deposits into the gambling account as being invalid (and any winnings arising from 	such deposit as void). Where an account is suspended, the relevant customer should contact 	us.
            </li>
            <li >1.3 {LocalConfig.gameName} may confirm a customer's address by posting an address verification letter to 	the customer. Enclosures may include a rule book, leaflet and telebet card. All 	correspondence will be discreet and the envelope will not display any reference to 	{LocalConfig.gameName}. When such correspondence is initiated, all offers and withdrawal requests may 	remain pending until the address has been confirmed as correct.</li>
            <li >1.4 By accepting the Terms and or registering to use the Website you hereby agree that we 	shall be entitled to conduct any and all such identification, credit and other verification 	checks from time to time that we may require and/or are required by applicable laws and 	regulations and/or by the relevant regulatory authorities for use of the Website and our 	products generally. You agree to provide all such information as we require in connection 	with such verification checks. We shall be entitled to suspend or restrict your account in any 	manner that we may deem in our absolute discretion to be appropriate, until such time as 	the relevant checks are completed to our satisfaction.</li>
            <li >1.5 As part of the registration process, we may supply your information details to authorised 	credit reference agencies to confirm your identity and payment card details. You agree that 	we may process such information in connection with your registration.</li>
            <li >1.6 Customers may open only one account.
              Should we identify any customer with more than one account we reserve the right to treat 	any such accounts as one joint account.</li>
            <li>1.7 Customers must keep their registration and account details up to date. This, and your 	account information, may be amended in the Personal section of the Website. If you require 	any assistance, please contact us.</li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>2. Account Details</h2>
          <ul>
            <li>2.1 {LocalConfig.gameName} allows all its customers to choose their own username and password 	combination.
              Customers must keep this information secret and confidential as you are responsible for all 	bets/wagers placed on your account and any other activities taking place on your account.
            </li>
            <li >2.2 Bets will stand if your username and password have been entered correctly or if your 	account has been accessed via Touch ID or the
              'Keep me logged in' facility (whether or not authorised by you), subject to there being 	sufficient funds in the account.
            </li>
            <li >
              2.3 If, at any time, you feel a third party is aware of your user name and or password you 	should change it immediately via the Website. Should you forget part or all of your 	combination, please contact us.
            </li>
            <li>
              2.4 Please note that cardholder details and any other sensitive data should never be sent to us by unencrypted email.
            </li>
            <li>
              2.5 The current balance and transaction history of your account may be viewed at any time 	once you have logged into your account on the Website.
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>3. Personal Details</h2>
          <ul>
            <li>
              3.1 {LocalConfig.gameName} will comply with applicable data protection laws in respect of the personal 	information you supply to us. Your personal information is processed in accordance with our 	Privacy Policy.
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>4. Suspension and Closure</h2>
          <ul>
            <li>
              4.1 If you want to close your account, please contact us. Any negative balance on your 	account will fall immediately due and payable to {LocalConfig.gameName}, and your account will not be 	closed until the relevant amount owed to {LocalConfig.gameName} is paid in full.
            </li>
            <li>
              4.2 {LocalConfig.gameName} reserves the right to close or suspend your account at any time and for any 	reason. Without limiting the preceding sentence, {LocalConfig.gameName} shall be entitled to close or 	suspend your account if:
              <p>(a) you become bankrupt;</p>
              <p>(b) {LocalConfig.gameName} considers that you have used the Website in a fraudulent manner or for illegal 	and/or unlawful or improper purposes;
              </p>
              <p>(c) {LocalConfig.gameName} considers that you have used the Website in an unfair manner, have 	deliberately cheated or taken unfair advantage of {LocalConfig.gameName} or any of its customers or if 	your account is being used for the benefit of a third party;
            </p>
              <p>(d) {LocalConfig.gameName} is requested to do so by the police, any regulatory authority or court;</p>
              <p>(e) {LocalConfig.gameName} considers that any of the events referred to in (a) to (c) above may have 	occurred or are likely to occur; or</p>
              <p>(f) your account is deemed to be dormant and its balance is, or reaches zero in accordance 	with paragraph B.5.1 below.</p>
            </li>
            <li>
              4.3 If {LocalConfig.gameName} closes or suspends your account for any of the reasons referred to in (a) to 	(e) above, you shall be liable for any and all claims, losses, liabilities, damages, costs and 	expenses incurred or suffered by {LocalConfig.gameName} (together "Claims") arising therefrom and shall 	indemnify and hold {LocalConfig.gameName} harmless on demand for such Claims. In the circumstances 	referred to in (a) to (e) above, {LocalConfig.gameName} shall also be entitled to withhold and/or retain any 	and all amounts which would otherwise have been paid or payable to you (including any 	winnings or bonus payments).
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>C. YOUR FINANCES</h2>
          <h3 class={style.help_content_h2}>1. Deposits and Wagers</h3>
          <ul>
            <li>
              1.1 You may only bet/wager with the amount of cleared funds held in your account. 	Accordingly, if you want to place bets or participate in gaming, you must deposit monies into 	your account. Further details of how to deposit, withdraw and transfer funds can be found 	on our website.
            </li>
            <li>
              1.2 By depositing tunds into your account, you direct us and we agree to hold them, along 	with any winnings, for the sole and specific purpose of using them (i) to place your sporting 	and gaming stakes; and (il) settling any fees or charges that you might incur in connection 	with the use of our services (Purpose). We shall be entitled to suspend or close your account 	if we reasonably consider or have reason to believe that you are depositing funds without 	any intention to place sporting and/or gaming stakes. In such circumstances we may also 	report this to relevant authorities.
            </li>
            <li>
              1.3 For payment methods that require a specific account holder, you should only deposit 	funds into your gambling account from a source where you are the named account holder. In 	such cases where {LocalConfig.gameName} identify that the named account holder differs from that which 	{LocalConfig.gameName} hold, we reserve the right to treat any deposits into the gambling account as 	being invalid (and any winnings arising from such deposit as void).
            </li>
            <li>
              1.4 All funds that we hold for you are held in separate designated customer bank accounts 	to be used solely for the Purpose and the bank has acknowledged this. We must return the 	funds to you if they are not used for the Purpose. The bank has acknowledged that your 	funds are held in those accounts solely for the Purpose. The bank has confirmed that it will 	not mix customers' funds in those accounts with any other, including our own, and that it 	has no clair on the funds itself. In view of the above, in the event of our insolvency, your 	funds are protected. The funds would still belong to you and would not fall into the hands of 	the liquidator. 1.5 You agree that we shall be entitled to retain any interest which might 	accrue on monies held in your {LocalConfig.gameName} account.
            </li>
            <li>
              1.5 No credit will be offered by any employee of {LocalConfig.gameName}, and all bets must be supported 	by sufficient funds in the customer account.
              {LocalConfig.gameName} reserves the right to void any bet/wager which may have inadvertently beer 	accepted when the account did not have sufficient funds to cover the bet/wager. Should 	funds be credited to a customer's account in error it is the customer's responsibility to 	inform {LocalConfig.gameName} without delay. {LocalConfig.gameName} will recover such funds by account adjustment.
            </li>
            <li>
              1.6 No credit will be offered by any employee of {LocalConfig.gameName}, and all bets must be supported 	by sufficient funds in the customer account.
              {LocalConfig.gameName} reserves the right to void any bet/wager which may have inadvertently beer 	accepted when the account did not have sufficient funds to cover the bet/wager. Should 	funds be credited to a customer's account in error it is the customer's responsibility to 	inform {LocalConfig.gameName} without delay. {LocalConfig.gameName} will recover such funds by account adjustment.
            </li>
            <li>
              1.7 Unless otherwise stated, all examples given on the Website are given in {LocationInfo().currencyName}. 	For any transaction which requires a currency exchange, the applicable exchange rate will be 	the mid-rate as quoted by the Financial Times.
            </li>
            <li>
              1.8 Once a currency is selected, funds are deposited, wagered and winnings paid in that 	currency. If you wish to change the currency used, please contact us.
            </li>
            <li>
              1.9 You are only entitled to use our currency exchange service for the purpose of placing 	bets or wagers via the Website (the " authorised purpose"). You are not entitled to use this 	service for any purpose other than the authorised purpose (including currency hedging, 	speculative trading or any other currency trading). If {LocalConfig.gameName} considers that you are using 	the Website for any reason other than the authorised purpose, {LocalConfig.gameName} shall be entitled 	to close or suspend your account and you shall:
              <p>(a) be liable for any and all claims incurred arising therefrom; and</p>
              <p>(b) indemnify {LocalConfig.gameName} on demand for such claims.</p>
            </li>
            <li>
              1.10 In addition, {LocalConfig.gameName} shall be entitled to withhold and/or retain any and all amounts 	earned or received by you as a result of or in connection with your 'unauthorised' use of the 	Website including activities which are not for an authorised purpose.
            </li>
            <li>
              1.11 {LocalConfig.gameName} does not charge for deposits made by Debit/Credit Card, however please be 	aware that some card issuers consider betting transactions as 'cash' payments and therefore 	may charge you a cash advance fee. Please check with your card issuer for further details.
            </li>
          </ul>
        </div>
        <div class={style.help_content_div}>
          <h3 class={style.help_content_h2}>2. Withdrawals</h3>
          <ul>
            <li>
              2.1 Where possible, all withdrawals will be processed to the payment account from which 	the deposits were made. Withdrawal payments can only be made in the name of and to the 	registered account holder.
            </li>
            <li>
              2.2 We have a separate bank account for the sole and specific purpose of protecting money 	that you have asked to withdraw whilst it is on its way to you. We agree with you to 	maintain a balance of our money in that account for that sole and specific purpose. 	Therefore, in the event of our insolvency before the withdrawn amount reaches you, an 	equivalent amount in that account would belong to you and would not fall into the hands of 	the liquidator. The bank has acknowledged that the balance in that account is held solely for 	the specific purpose mentioned. The bank has confirmed that it will not mix the balance 	with any other, including our other balances, and that it has no claim on the funds itself.
            </li>
            <li>
              2.3 If the value of a deposit is not played through in full before a withdrawal is requested, 	{LocalConfig.gameName} reserves the right to make a charge to the customer's account to cover all 	reasonable costs relating to both the deposit and withdrawal. If necessary, the value of the 	withdrawal requested may be reduced accordingly.
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h3 class={style.help_content_h2}>D. YOUR OBLIGATIONS</h3>
          <ul>
            <li>
              1.0 In accepting these terms and conditions, you irrevocably and unconditionally represent 	and warrant, and are bound without reservation or limitation (as the case may be) to such 	representations and warranties, that:
            </li>
            <li>
              1.1 You are at least 18 years of age, or the age of consent or majority in your home 	jurisdiction, whichever is higher, specifically to undertake gambling activity;
            </li>
            <li>
              1.2 You are of legal age to enter into contracts of this nature, and you are aware that 	underage gambling is an offence;
            </li>
            <li>
              1.3 You have not previously self-excluded yourself from gambling on our Website either on a 	permanent basis or under currently valid period of self-exclusion, nor are you a 	self-confessed problem gambler;
            </li>
            <li>
              1.4 You will provide true and accurate registration information when opening your Account, 	including without limitation your correct name and date of birth, your current address, 	email address and personal telephone number and any other Personal Information 	reasonably requested by us, and you will inform us immediately of any changes to the same;
            </li>
            <li>
              1.5 You will notify us promptly of any error in your Account information of which you 	become aware;
            </li>
            <li>1.6 Your Account with us is solely for your own personal use;</li>
            <li>1.7 You are not depositing funds originating from criminal and/or illegal activities;</li>
            <li>1.8 You are legally able to participate in Online Gambling within your home jurisdiction;</li>
            <li>1.9 You have the mental capacity to take responsibility for your own actions and to be bound 	by the Rules and Regulations;</li>
            <li>1.10 You will not represent yourself as an agent or affiliate of the Company without a 	written statement to that effect from the Company; and furthermore you shall, in no way 	market, advertise, publicise, or promote the Company or the Company's Services without 	the previous written consent of the Company;</li>
            <li>1.11 You will use our Website for your own entertainment and recreational purposes only 	and not as a means to make a living;</li>
            <li>1.12 You will not engage in, attempt to engage in or incite or otherwise cause any other 	person to engage in any Prohibited Practice;</li>
            <li>1.13 It is your sole responsibility to ensure compliance with your applicable local or national 	laws before registering and/or placing Bets with the Company;</li>
            <li>1.14 You will not allow or authorise any other person or third party (including but without 	limitation to, any minor) to use the Company's services, use your Account or accept any 	Winnings on your behalf; and/or</li>
            <li>1.15 You are not currently or (have not been within the last six months) an employee or 	contractor of the Company or immediate family member of each, whereby immediate family 	members shall include, but not limited to, spouse, children, parents, siblings, grandparents 	and grandchildren.</li>
            <li>1.16 You are not currently or (have not been within the last six months) an employee or 	contractor of the Company or immediate family member of each, whereby immediate family 	members shall include, but not limited to, spouse, children, parents, siblings, grandparents 	and grandchildren.</li>
          </ul>
        </div>


        <div class={style.help_content_div}>
          <h3 class={style.help_content_h2}>2 You acknowledge and agree:</h3>
          <ul>
            <li>
              2.1 That the Company is legally licensed for the provision of betting services (hence the
              Company taking the position that it is rendering Services) from a jurisdiction where it is 	legally permissible to do so, and that the Company is making an effort not to take business 	from Prohibited Jurisdictions or customers in or from Prohibited Jurisdictions;
            </li>
            <li>
              2.2 Your use this Website at your sole risk and further understand and agree that by using 	the Website and/or the Services offered herein, you may lose money on Bets placed and you 	accept full responsibility for any such loss;
            </li>
            <li>
              2.3 You will forthwith report any and all Winnings to the proper authorities if you reside in a 	jurisdiction where such Winnings are taxable or are otherwise required by law to be 	disclosed, and the Company shall not, in anyway, be liable for your failure to do so; and/or
            </li>
            <li>
              2.4 If you wish to place a Bet with the Company that there may nevertheless be specific laws 	in your country, place of residence, or the place where such Bets are placed from, which 	prohibit Online Gambling or the use and access of this Website; and that accordingly, you 	will not access or register on the Website at any time from within a jurisdiction that 	prohibits the access or use of this Website for any reason whatsoever; from within a 	jurisdiction that prohibits Online Gambling; or if you are a citizen or resident of a Prohibited 	Jurisdiction; or if the payment of any Winnings to you would expose the Company to any 	sanction, prohibition or restriction, including United Nations resolutions or the trade or 	economic sanctions rules or regulations of the European Union, United Kingdom, Isle of Man 	or United States of America;
            </li>
            <li>
              2.5 To take independent legal advice on any aspect of these Rules and Regulations and any 	aspect of betting and gambling on this Website in advance of commencing play;
            </li>
            <li>
              2.6 To fully indemnify, defend and hold the Company, and its officers, directors, employees, 	consultants, advisors, agents and suppliers harmless, immediately upon demand, from and 	against all claims, liabilities, damages, losses, costs and expenses, including legal fees, 	(regardless of theory of law) arising out of suffered by us as a result of any of the following:
              <p>- your breach of any of the Rules and Regulations;</p>
              <p>- your engagement or participation in Prohibited Practice, Fraud, Wrongdoing;</p>
              <p>- any money laundering activity associated with you and/or your Account with the Company;</p>
              <p>- any other liabilities arising out of your use of the Website or where you permit use by any other person, including your failure to keep your Account Information secure.</p>
            </li>
            <li>3.0 If it is reasonably suspected or determined by the Company, using reasonable measures 	and at its sole discretion, that:</li>
            <li>3.1 You are a minor or mentally incapacitated individual lacking the legal ability to consent 	to the making of contract;</li>
            <li>3.2 You failed to abide by any Rule or Regulation (including engaging in or contributing to 	any Wrongdoing, Fraud or Prohibited Practice) or are suspected of money-laundering or 	other criminal activity;</li>
            <li>3.3 that you have "charged back" or denied any of the purchases or deposits that you made 	to your Account;</li>
            <li>3.4 In our reasonable opinion your continued use of the Services may be detrimental to the 	Company's regulated status, including its continued ability to be licensed by the Isle of Man 	Gambling Supervision Commission; and/or</li>
            <li>3.5 You become bankrupt or suffer analogous proceedings anywhere in the world; then the 	Company shall have the right, in respect of your Account (and/or any other {LocalConfig.gameName} group 	company account held by you) without limiting its ability to seek alternative remedies, and 	in its sole and absolute discretion:
              <p>- to subject you to checks to further verify your Personal Information;</p>
                <p>- to carry out further investigations into your Account, patterns of play and/or your 	background;</p>
                  <p>- to notify its regulator and/or the police of any suspicions we may have;</p>
                    <p>- to cancel, close, freeze or suspend your Account indefinitely and to terminate the provision 	of all Services to you;</p>
                      <p>- to void any Bets or withhold your funds or deem your funds forfeit;</p>
                        <p>- to withhold the whole or part of the balance and/or recover from the Account the amount 	of any deposits, pay-outs, bonuses or Winnings which have been affected by or are in any 	way attributable to any of the event (s) contemplated in any other section;</p>etand
                          <p>- to take legal action against you; and/or</p>
                            <p>- to restrict your ability to use the Website or Services, to place a Bet or otherwise wager 	with Us.</p></li>
            <li>3.6 The rights set out in this section are without prejudice to any other rights (including any 	common law rights) that we may have against you, whether under these Terms and 	Conditions or otherwise.</li>
            <li>3.7 In exercising any of our rights under this section, the Company shall use reasonable 	endeavours to ensure that, while complying with the Company's regulatory and other legal 	obligations, the Company exercises such rights in a manner which is fair to you and to its 	other customers.</li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>E. BETTING PROCEDURES</h2>
          <h3 class={style.help_content_h2}>1. Placing Bets/Wagers</h3>
          <ul>
            <li>
              1.1 {LocalConfig.gameName} reserves the right to decline all, or part, of any bet/wager requested at its 	sole and absolute discretion. All bets/wagers are placed at your own risk and discretion.
            </li>
            <li>
              1.2 We only accept bets/wagers made online (including via mobile device or downloadable 	applications on a mobile device) or by mobile sms. Bets/wagers are not accepted in any 	other form (post, email, fax, etc.) and where received will be invalid and void - win or lose.
            </li>
            <li>
              1.3 It is the responsibility of the customer to ensure details of their bets/wagers are correct.
              Once bets/wagers have been placed they may not be cancelled or changed by the customer.
              {LocalConfig.gameName} reserves the right to cancel any bet/wager at any time.
            </li>
            <li>
              1.4 Your funds will be allocated to bets/wagers in the order they are placed and will not be 	available for any other use. {LocalConfig.gameName} reserves the right to void and/or reverse any 	transactions made after a bet/wager has been placed involving allocated funds, either at the 	time or retrospectively.
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h3 class={style.help_content_h2}>2. Bet/Wager Confirmation</h3>
          <ul>
            <li>
              2.1 Bets/wagers will not be valid if there are insufficient funds in your account.
            </li>
            <li>
              2.2 For telephone bets/wagers 'acceptance confirmed' will be deemed only after an 	operator has completed a full read back of the bet/wager details and confirmed to the 	customer that the bet/wager has been successfully processed and accepted.
            </li>
            <li>
              2.3 A bet/wager that you request will only be valid once accepted by {LocalConfig.gameName} 's servers.
              Each valid bet/wager will receive a unique transaction code. We shall not be liable for the 	settlement of any bets/wagers which are not issued with a unique transaction code. If you 	are unsure about the validity of a bet/wager, please check your account history, or Contact 	Us.
            </li>
            <li>
              2.4 Notwithstanding the above, if bets/wagers are placed via a credit or debit card they are 	not valid until we have received payment in full. The bet/wager will be automatically void if 	we have not received the payment prior to the relevant event commencing.
            </li>
            <li>2.5 Should a dispute arise, you a agree that the {LocalConfig.gameName} transaction log database will 	be the ultimate authority in such matters.</li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h3 class={style.help_content_h2}>3. Settlement and Payouts</h3>
          <ul>
            <li>
              3.1 All bets and wagers are subject to the Betting Coverage provisions (including maximum 	winnings) set out in Appendix Two to these Terms and Conditions.
            </li>
            <li>
              3.2 {LocalConfig.gameName} reserves the right to suspend a market and/or cancel any bet/wager at anytime.
              <p>When a market is suspended any bets entered will be rejected. {LocalConfig.gameName} also reserves the 	right to cease betting on any markets at any time without notice.</p>
            </li>
            <li>
              3.3 The 'To Return' calculation available on the Website is for information purposes only, and 	all bets will be calculated using the stake/risk at the odds accepted. In multiple bets/parlays 	with a void selection(s) the 'To Return' figure is reduced accordingly.
            </li>
            <li>
              3.4 Should a customer include a non-runner or void selection in a multiple bet/parlay, the 	bet will be settled on the remaining selections.
            </li>
            <li>3.5 Winnings from settled bets/wagers are added to the balance of your betting account.
              Any funds/winnings credited to an account in error are not available for use, and {LocalConfig.gameName} 	reserves the right to void any transactions involving such funds and/or withdraw the 	relevant amount from your account and or reverse the transaction, either at the time or 	retrospectively.</li>
            <li>3.6 {LocalConfig.gameName} reserves the right to withhold payment and to declare bets on an event void 	if we have evidence that the following has occurred: (i) the integrity of the event has been 	called into question; (i) the price (s) or pool has been manipulated; or (it) match rigging has 	taken place. Evidence of the above may be based on the size, volume or pattern of bets 	placed with {LocalConfig.gameName} across any or all of our betting channels. A decision given by the 	relevant governing body of the sport in question (if any) will be conclusive. If any customer 	owes any money to {LocalConfig.gameName} for any reason, we have the right to take that into account 	before making any payments to that customer.</li>
            <li>3.7 Where there is evidence of a series of bets each containing the same selection(s) having 	been placed by or for the same individual or syndicate of individuals, {LocalConfig.gameName} reserves the 	right to make bets void or withhold payment of returns pending the outcome of any 	subsequent investigation.</li>
            <li>3.8 For events where there is no official 'off' declared, the advertised start time of the event 	will be deemed the 'off'. If for any reason a bet is inadvertently accepted after an event or 	match has commenced (other than live In-Play betting clearl indicated on the Website. bets 	will stand providing the final outcome is not known, and that no participant/team has 	gained any material advantage (e.g. a score, sending off for the other team, etc) at the time 	the bet was placed.
              If the outcome of an event/market is known
              {LocalConfig.gameName} reserves the right to void the bet, win or lose. Disputes over times bets are 	placed will be settled using the transaction log. All times stated on the Website and/or 	referred to by {LocalConfig.gameName} staff relate to {LocationInfo().name} Time unless stated otherwise.</li>
            <li>3.9 In-Play betting - where we have reason to believe that a bet is placed after the outcome 	of an event is known, or after the selected participant or team has gained a material 	advantage (e.g. a score, sending off for the other team etc) we reserve the right to void the 	bet, win or lose.</li>
            <li>3.10 If for any reason we are unable to validate the outcome of a particular market (e.g. due 	to loss of live pictures), all bets will be void, unless settlement of bets is already determined.</li>
            <li>3.11 Where a customer gives ambiguous instructions, {LocalConfig.gameName} reserves the right to split 	the amount of monies staked between the potential outcomes. Where this is impractical we 	reserve the right to void the whole of that bet. In any event, the decision of {LocalConfig.gameName} is 	final.</li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>E. USE OF THE WEBSITE</h2>
          <h3 class={style.help_content_h2}>1. Software and Technology Issues</h3>
          <ul>
            <li>
              1.1 If for any reason we are unable to validate the outcome of a particular market on real 	and/or virtual sports ({LocalConfig.gameName} League) then all bets will be void, unless settlement of bets 	is already determined. Moreover, due to the nature of how our products work, there are a 	number of factors that may cause you to experience technical issues when 	accessing/navigating areas of the site or with the site's general functionality.
              Possible issues could be but not limited to:
              <p>- Loss of live pictures</p>
                <p>- Display and functionality issues</p>
                  <p>- Problems Depositing/Withdrawing funds</p>
                    <p>- Problems logging in</p>
                      <p>- Unexpected unusual error messages</p>
                        <p>- Unresponsive links</p>
              Should you experience any of the above, we have a 24hr dedicated Customer Technical 	Support team who will be happy to troubleshoot any issues with you. Please Contact Us if 	you require assistance. Further to this, In order for you to use certain products offered on 	the Website you may need to download some software (for example, casino games that are 	made available via a flash player). Also, certain third party product providers may require 	you to agree to additional terms and conditions governing the use of their products. If you 	do not accept those third party terms and conditions, do not use the relevant third party 	software. {LocalConfig.gameName} does not accept any liability in respect of any third party software.
            </li>
            <li>
              1.2 You are only permitted to use any and all software made available to you via the Website 	for the purpose of using products on the Website and, save to the extent permitted by 	applicable law, for no other purposes whatsoever.
            </li>
            <li>
              1.3 We hereby grant to you a personal, non-exclusive, non-transferable right to use the 	relevant software, for the sole purpose of using/playing products on the Website (including 	the online casino), in accordance with the following provisions.
              <p>(a) You are not permitted to: (i) install or load the software onto a server or other networked 	device or take other steps to make the software available via any form of "bulletin board", 	online service or remote dial-in or network to any other person; (ii) sub-license, assign, rent, 	lease, loan, transfer or copy (except as expressly provided elsewhere in these terms and 	conditions) your licence to use the software or make or distribute copies of the software; (it) 	enter, access or attempt to enter or access or otherwise bypass {LocalConfig.gameName} 's security system 	or interfere in any way (including but not limited to, robots and similar devices) with the 	relevant products or the Website or attempt to make any changes to the software and/or 	any features or components thereof; or (iv) copy or translate any user documentation 	provided 'online' or in electronic format. In addition, and except to the minimum extent 	permitted by applicable law in relation to computer programs you are not permitted to: (a) 	translate, reverse engineer, decompile, disassemble, m{LocalConfig.gameName}fy, create derivative works 	based on, or otherwise m{LocalConfig.gameName}fy the software; or (b) reverse engineer, decompile, 	disassemble, m{LocalConfig.gameName}fy, adapt, translate, make any attempt to discover the source code 	of the software or to create derivative works based on the whole or on any part of the 	software.</p>
              <p>(b) You do not own the software.</p>
              <p>(c) The software is provided "as is" without any warranties, conditions, undertakings or 	representations. {LocalConfig.gameName} does not warrant that: (i) the software will meet your 	requirements; (i) the software will not infringe any third party's intellectual property rights; 	(il) the operation of the software will be error free or uninterrupted; (iv) any defects in the 	software will be corrected; or (v) the software or the servers are virus-free.</p>
              <p>(d) In the event of communications or system errors occurring in connection with the 	settlement of accounts or other features or components of the software, neither {LocalConfig.gameName} 	nor the Software Provider will have any liability to you or to any third party in respect of 	such errors. {LocalConfig.gameName} reserves the right in the event of such errors to remove all relevant 	products from the Website and take any other action to correct such errors.</p>
              <p>(e) You hereby acknowledge that how you use the software is outside of {LocalConfig.gameName}' control 	and at your own risk.</p>
              <p>(f) The software may include confidential information which is secret and valuable to the 	Software Provider and/or {LocalConfig.gameName}. You are not entitled to use or disclose that confidential 	information other than strictly in accordance with these Terms and Conditions.</p>
            </li>
            <li>
              1.4 While {LocalConfig.gameName} endeavours to ensure that the Website is available 24 hours a day,
              {LocalConfig.gameName} shall not be liable if for any reason the Website is unavailable at any time or for 	any period. We reserve the right to make changes or corrections to or to alter, suspend or 	discontinue any aspect of the Website and the content or services or products available 	through it, including your access to it.
            </li>
            <li>
              1.5 You must not misuse the Website by introducing viruses, Trojans, worms, logic bombs or 	other material which is malicious or technologically harmful. In particular, you must not 	access without authority, interfere with, damage or disrupt the Website or any part of it; any 	equipment or network on which the Website is stored; any software used in connection with 	the provision of the Website; or any equipment, software or website owned or used by a 	third party. You must not attack our Website via a denial-of-service attack. Save where we 	have not taken reasonable steps, we will not be liable for any loss or damage caused by a 	distributed denial-of-service attack, viruses or other technologically harmful material that 	may infect your computer equipment, computer programs, data or other proprietary 	material due to your use of the Website, software or to your downloading of any material 	posted on it, or on any website linked to it.
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h3 class={style.help_content_h2}>2. Third Party Content</h3>
          <ul>
            <li>
              2.1 {LocalConfig.gameName} receives feeds, commentaries and content from a number of suppliers. 	Certain third party product providers may require you to agree to additional terms and 	conditions governing the use of their feeds, commentaries and content. If you do not accept 	the relevant third party terms and conditions, do not use the relevant feeds, commentaries 	or content.
            </li>
            <li>
              2.2 {LocalConfig.gameName} does not accept any liability in respect of any third party feeds, commentaries 	and content.
            </li>
            <li>
              2.3 {LocalConfig.gameName} does not allow any employee, anyone else in any way connected to such 	employee or anyone otherwise connected to a third party service provider (to be 	determined in the Company's absolute discretion) to bet/wager on any market or event 	where the third party service provider is providing a service to {LocalConfig.gameName}. {LocalConfig.gameName} will 	void any bet/wager where it determines in its absolute discretion that such 	betting/wagering has taken place.
            </li>
            <li>
              2.4 Where the Mobile service, Website and all other media/electronic platforms, contains 	links to third party resources, these links are provided for your information only. {LocalConfig.gameName} 	has no control over the content of these sites or resources, and accepts no liability for them 	or for any loss or damage that may arise from your use of them. The inclusion of a link to a 	third party does not constitute an endorsement of that third party's product or services (if 	applicable).
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h3 class={style.help_content_h2}>Other</h3>
          <ul>
            <li>
              3.1 {LocalConfig.gameName} actively monitors traffic to and from the Website. {LocalConfig.gameName} reserves the 	right in its sole discretion to block access where evidence indicative of automated or robotic 	activity is found.
            </li>
            <li>
              3.2 {LocalConfig.gameName} reserves the right to restrict access to all or certain parts of the Website in 	respect of certain jurisdictions.
            </li>
            <li>
              3.3 {LocalConfig.gameName} may amend the products offered via the Website at any time and for any 	reason
            </li>
            <li>
              3.4 From time to time, all or part of the Website may be unavailable for because of our 	maintenance of the Website and/or alteration or amendment of any of the products.
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>F. OUR LIABILITY</h2>
          <ul>
            <li>
              1. {LocalConfig.gameName} does not accept any liability for any damages, liabilities or losses which are 	deemed or alleged to have arisen out of or in connection with the Website or its content 	(including delays or interruptions in operation or transmission, communication or lines 	failure, any person's misuse of the Website or its content or any errors or omissions in 	content).
            </li>
            <li>
              2. While {LocalConfig.gameName} endeavours to ensure that the information on the Website is correct, 	{LocalConfig.gameName} does not warrant the accuracy or completeness of the information and material 	on the Website. The Website may contain typographical errors or other inaccuracies, or 	information that is out of date. {LocalConfig.gameName} is under no obligation to update such material. 	The information and material on the Website is provided "as is", without any conditions, 	warranties or other terms of any kind.
              Accordingly, to the maximum extent permitted by law, {LocalConfig.gameName} provides you with the 	Website on the basis that {LocalConfig.gameName} excludes all representations, express or implied 	warranties, conditions and other terms which but for these terms and conditions might have 	effect in relation to the Website.
            </li>
            <li>
              3. {LocalConfig.gameName} 's total aggregate liability to you under or in connection with these Terms and 	Conditions does not exceed:
              <p>(a) the value of the bets and or wagers you placed via your account in respect of the 	relevant bet/wager or product that gave rise to the relevant liability; and</p>
              <p> (b) the amount of applicable monies, where such monies have been misplaced by us.</p>
            </li>
            <li>
              4. Except as otherwise set out in these terms, {LocalConfig.gameName} shall not be liable, in contract, tort 	(including negligence) or for breach of statutory duty or in any other way for any of the 	following (whether incurred directly or indirectly):
              <p> (a) loss of profits;</p>
              <p>(b) loss of business;</p>
              <p>(c) loss of revenue;</p>
              <p>(d) loss of opportunity;</p>
              <p>(e) loss of data;</p>
              <p>(f) loss of goodwill or reputation; or</p>
              <p>(g) any special, indirect or consequential losses, whether or not such losses were within the 	contemplation of the parties at the date of these terms and conditions.</p>
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>G. OUR INTELLECTUAL PROPERTY RIGHTS</h2>
          <ul>
            <li>
              1. The contents of the Website are protected by international copyright laws and other 	intellectual property rights. The owner of these rights is {LocalConfig.gameName}, its affiliates or other 	third party licensors.
            </li>
            <li>
              2. All product and company names and logos mentioned on the Website are the trademarks, 	service marks or trading names of their respective owners, including {LocalConfig.gameName}.
            </li>
            <li>
              3. Except to the extent required to use a product for the purpose of placing bets or wagers, 	no part of the Website may be reproduced or stored, m{LocalConfig.gameName}fied, copied, republished, 	uploaded, posted, transmitted or distributed, by any means or in any manner, or included in 	any other website or in any public or private electronic retrieval system or service including 	text, graphics, video, messages, code and/or software without our express prior written 	consent.
            </li>
            <li>
              4. If you make use of a feature that allows you to upload material, information, comments, 	postings or other content to the Website ("User Content"), then the User Content will be 	considered to be non-confidential and nonproprietary and {LocalConfig.gameName} has the right to use, 	copy, distribute and disclose to third parties any User Content for any purpose. {LocalConfig.gameName} 	also has the right to disclose your identity to any third party who is claiming that any User 	Content posted or uploaded by you to the Website constitutes a violation of their 	intellectual property rights or of their right to privacy. {LocalConfig.gameName} has the right to remove, 	amend or edit any User Content you make on the Website.
            </li>
            <li>
              5. Any commercial use or exploitation of the Website or its content is strictly prohibited.
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>H. OTHER PROVISIONS</h2>
          <ul>
            <li>
              1. These Terms and Conditions, the Privacy Policy, the Cookies Policy, the Rules and any 	document expressly referred to in them and any guidelines or rules posted on the Website 	constitute the entire agreement and understanding of the parties and supersede any 	previous agreement between the parties relating to their subject matter. You acknowledge 	and agree that in entering into and agreeing to these Terms and Conditions, the Privacy 	Policy, the Cookies Policy, the Rules and any document expressly referred to in them and any 	guidelines or rules posted on the Website you do not rely on, and shall have no remedy in 	respect of, any statement, representation, warranty, understanding, promise or assurance 	(whether negligently or innocently made of any person (whether party to this agreement or 	not) other than as expressly set out therein. Nothing in this clause shall operate to limit or 	exclude any liability for fraud or fraudulent misrepresentation.
            </li>
            <li>
              2. In no event will any delay, failure or omission (in whole or in part) in enforcing, exercising 	or pursuing any right, power, privilege, claim or remedy conferred by or arising under these 	Terms and Conditions or by law, be deemed to be or construed as a waiver of that or any 	other right, power, privilege, claim or remedy in respect of the circumstances in question, or 	operate so as to bar the enforcement of that, or any other right, power, privilege, claim or 	remedy, in any other instance at any time or times subsequently.
            </li>
            <li>
              3. The rights and remedies provided by these
              Terms and Conditions are cumulative and (unless otherwise provided in these Terms and 	Conditions) do not exclude any other rights or remedies available in law.
            </li>
            <li>
              4. If any provision of these Terms and Conditions is found by any court or administrative 	body of competent jurisdiction to be invalid or unenforceable, such invalidity or 	unenforceability shall not affect the other provisions of these Terms and Conditions which 	shall remain in full force and effect.
            </li>
            <li>
              5. You shall execute or cause to be executed all documents and do or cause to be done all 	further acts and things consistent with the terms of these Terms and Conditions that 	{LocalConfig.gameName} may from time to time reasonably require in order to vest in and secure to 	{LocalConfig.gameName} the full benefit of rights and benefits to be transferred or granted to {LocalConfig.gameName} 	under these Terms and Conditions and for the protection and enforcement of the same and 	otherwise to give full effect to the terms of these Terms and Conditions.
            </li>
            <li>6. Nothing in these Terms and Conditions shall create or be deemed to create a partnership, 	joint venture or principal-agent relationship between the parties and no party shall have 	authority to bind any other in any way unless expressly provided otherwise in these Terms 	and Conditions.</li>
            <li>7. {LocalConfig.gameName} shall not be in breach of these Terms and Conditions nor liable for delay in 	performing, or failure to perform, any of its obligations if such delay or failure results from 	events, circumstances or causes beyond its reasonable control including (without limitation) 	any telecommunications network failures, power failures, failures in third party computer 	hardware or software, fire, lightning, explosion, flood, severe weather, industrial disputes or 	lock-outs, terrorist activity and acts of government or other competent authorities. In such 	circumstances the time for performance shall be extended by a period equivalent to the 	period during which performance of the obligation has been delayed or failed to be 	performed.</li>
            <li>8. {LocalConfig.gameName} may assign, transfer, charge, sub-license or deal in any other manner with 	these Terms and Conditions, or sub-contract any of its rights and obligations under these 	Terms and Conditions, to any party including any company within the {LocalConfig.gameName} group.</li>
            <li>9. Any notice to be given under these Terms and Conditions must be in writing, in English 	and may be served by hand delivery, first class post, recorded delivery or registered post or 	airmail, email or fax to: (a) in the case of {LocalConfig.gameName}, the address of the relevant {LocalConfig.gameName} 	company as set out at the beginning of these Terms and Conditions or as displayed on the 	Website; and
              (b) in respect of notices given by {LocalConfig.gameName} to you, pursuant to the customer registration 	procedure (including any amendment to those details you have notified to {LocalConfig.gameName}). Any 	notice shall be deemed to have been received:
              (a) if delivered by hand, at the time of delivery;
              (b) if sent by first class post, recorded delivery or registered post, at 09.30 (GMT) on the 	second clear day after the date of posting; (c) if sent by pre-paid registered airmail, at 09.30 	(GMT) on the fifth clear day after the date of posting; (d) if sent by email, at the time of 	sending; and (e) if sent by fax, at the time of transmission by the sender.</li>
            <li>10. The Appendices, the Privacy Policy, the Cookies Policy, the Rules and any document 	expressly referred to in them and any guidelines or rules posted on the Website form an 	integral part of these Terms and Conditions and shall have effect as if set out in full in the 	body of these Terms and Conditions. In the event of any inconsistency between the main 	body of these Terms and Conditions and the Appendices, the Privacy Policy, the Cookies 	Policy, the Rules and/or any document expressly referred to in them and any guidelines or 	rules posted on the Website, unless stated otherwise, the main body shall prevail.</li>
          </ul>
        </div>
        <div class={style.help_content_div_last}></div>
      </div>
    </div>
  );
};

export default Termcond;
