import { useEnhancedHistory } from "@/data/RouterProvider";
import { LanguageContext } from "@/data/LanguageProvider";
import { ChannelRemind } from "@/utils/UrlHelper";
import React from "react";
import style from "./OtherStyle.less";

const HomeHeaderOther = (props) => {
  let history = useEnhancedHistory();
  const { langText } = React.useContext(LanguageContext)
  const backClick = () => {
    if (props && props.backToUrl) {
      if(parseInt(props.backToUrl, 10)){
        history.goBack(parseInt(props.backToUrl, 10));
        return;
      }
      history.replace(ChannelRemind(props.backToUrl));
      return;
    }
    history.replace(ChannelRemind("/home"));
  };
  const homeClick = () => {
    history.replace(ChannelRemind("/home"));
  };
  return (
    <div class={style.outline}>
      <div class={style.header_content}>
        <div class={`${style.header_two_separate} ${style.header_left}`}>
          <div class={style.back_btn} onClick={backClick}>
            {langText("btn_back")}
          </div>
        </div>
        <div class={style.header_middle}>{props.title}</div>
        <div class={`${style.header_two_separate} ${style.header_right}`}>
        <div class={style.home_other_btn} />
        </div>
      </div>
    </div>
  );
};

export default HomeHeaderOther;
