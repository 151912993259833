import React from "react";
import style from "./style.less";
import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { LanguageContext } from "@/data/LanguageProvider";
import { LocalConfig, PaymentConfig } from "@/config";
import { LocationInfo } from "@/data/LocationInfo";


const Help = () => {
  const PaymentsText = PaymentConfig.payments.map(payment=>payment.text).join(", ");
  const { langText } = React.useContext(LanguageContext);
  return (
    <div class={style.page_container}>
      <HomeHeaderOther />
      <div class={style.help_content_page}>
        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>{langText("help_title_1")}</h2>
          <ul>
            <li>{langText("help_tips_1")}
              <span class={style.link_help}>{LocationInfo().domain}</span>
            </li>
            <li >
              {langText("help_tips_2_1", {param1:LocalConfig.gameName})}
              <span class={style.help_link_help_2}>{langText("help_tips_2_2")}</span></li>
            <li >{langText("help_tips_3")}</li>
            <li >{langText("help_tips_4")}</li>
          </ul>
          <p class={style.bold}>{langText("help_tips_5")}
            <span class={style.link_help_note}> {LocationInfo().domain}</span>
          </p>
        </div>

        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>{langText("help_title_2")}</h2>
          <h2 class={style.help_content_page_h2}>{langText("help_title_3")}</h2>
          <ul>
            <li>{langText("help_tips_6")}
              <span class={style.link_help}>{LocationInfo().domain}</span>
            </li>
            <li >{langText("help_tips_7", {param1:LocalConfig.gameName})}
            </li>
            <li >{langText("help_tips_8")}</li>
            <li >{langText("help_tips_9")}</li>
            <li >{langText("help_tips_10")}</li>
            <li >{langText("help_tips_11")}</li>
            <li >{langText("help_tips_12")}</li>
          </ul>
        </div>

        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>{langText("help_title_4")}</h2>
          <ul>
            <li>{langText("help_tips_13")}
              <span class={style.link_help}>{LocationInfo().domain}</span>
            </li>
            <li >{langText("help_tips_14", {param1:LocalConfig.gameName})}
            </li>
            <li >{langText("help_tips_15")}</li>
            <li >{langText("help_tips_16")}</li>
            <li >{langText("help_tips_17")}</li>
            <li >{langText("help_tips_18")}</li>
          </ul>
        </div>

        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>{langText("help_title_8")}</h2>
          <ul>
            <li>{langText("help_tips_19")}
              <span class={style.link_help}>{LocationInfo().domain}</span>
            </li>
            <li >{langText("help_tips_20", {param1:LocalConfig.gameName})}
            </li>
            <li >{langText("help_tips_21")}</li>
            <li >{langText("help_tips_22")}</li>
            <li >{langText("help_tips_23")}</li>
          </ul>
        </div>

        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>{langText("help_title_5", {param1:PaymentsText})}</h2>
          <div class={style.help_content_page_deposit}>
            {langText("help_tips_24", {param1:LocationInfo().domain})}
          </div>
          <h2 class={style.help_content_page_h2}>{langText("help_title_6")}</h2>
          <ul>
            <li>{langText("help_tips_25", {param1:PaymentsText})}
            </li>
            <li>{langText("help_tips_26", {param1:PaymentsText})}
            </li>
            <li >{langText("help_tips_27", {param1:LocalConfig.gameName})}</li>
            <li >{langText("help_tips_28")}</li>
            <li >{langText("help_tips_29", {param1:PaymentsText})}</li>
            <li >{langText("help_tips_30")}</li>
          </ul>
        </div>

        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>{langText("help_title_7")}</h2>
          <ul>
            <li>{langText("help_tips_31")}
              <span class={style.link_help}>{LocationInfo().domain}</span>
            </li>
            <li >{langText("help_tips_32")}</li>
            <li >{langText("help_tips_33")}</li>
            <li >{langText("help_tips_34")}</li>
            <li >{langText("help_tips_35")}</li>
            <li >{langText("help_tips_36")}</li>
          </ul>
          <div class={style.help_content_page_div_last}>
            {langText("help_tips_37")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
