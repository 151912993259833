import React,{ useContext }  from "react";
import style from "./style.less";
import { Toast } from "react-vant";
import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PersistContext } from "@/data/PersistProvider";
import { LanguageContext } from "@/data/LanguageProvider";
import { FunctionalConfig, LocalConfig } from "@/config";

const Contact = () => {
  const { getPersist } = useContext(PersistContext);
  const { langText } = useContext(LanguageContext);
  const bgSettings = getPersist("bgSettings");
  // let whatsApp = "85253053474";
  // if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.whatsapp) {
  //   whatsApp = bgSettings.settings.client_config.whatsapp;
  // }
  let contactType = "WhatsApp"
  let contactNumber = "85253053474"
  let contactCopy = ""
  let contactType2 = ""
  let contactNumber2 = ""
  let contactCopy2 = ""
  if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.contactType) {
    contactType = bgSettings.settings.client_config.contactType;
  }
  if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.contactNumber) {
    contactNumber = bgSettings.settings.client_config.contactNumber;
  }
  if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.contactCopy) {
    contactCopy = bgSettings.settings.client_config.contactCopy;
  }else{
    contactCopy = contactNumber
  }

  if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.contactType2) {
    contactType2 = bgSettings.settings.client_config.contactType2;
  }
  if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.contactNumber2) {
    contactNumber2 = bgSettings.settings.client_config.contactNumber2;
  }
  if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.contactCopy2) {
    contactCopy2 = bgSettings.settings.client_config.contactCopy2;
  }else{
    contactCopy2 = contactNumber2
  }

  return (
    <div class={style.page_container}>
      <HomeHeaderOther title={langText("profile_btn_5")} />
      <div class={style.contact_content}>
        <div class={style.contact_content_div}>
          <img
            src={require("@/assets/images/home/whats_app.png").default}
            style={{ width: "2.5rem", marginTop: "0.8rem", marginLeft: "1.5rem", marginBottom: "0.8rem" }}
          />
          <span>{contactType}: 
                {FunctionalConfig.CustomerContactClickable ? (
                  <a
                    href={contactCopy}
                    style={{ textDecoration: "underline", marginLeft: "0.5rem" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {contactNumber}
                  </a>
                ) : (
                  contactNumber
                )}</span>
          <CopyToClipboard
            text={contactCopy}
            onCopy={() => {
              Toast.success({
                message: "Copy Successfully",
              });
            }}
          >
            <img
              style={{ width: "1.7rem", height: "1.7rem", margin: "1.2rem 0 0 1.9rem" }}
              src={require("@/assets/icons/copy.png").default}
            />
          </CopyToClipboard>
        </div>

        {contactType2 != "" && contactNumber2 != "" && (
          <div class={style.contact_content_div}>
            <img
              src={require("@/assets/images/home/whats_app.png").default}
              style={{ width: "2.5rem", marginTop: "0.8rem", marginLeft: "1.5rem", marginBottom: "0.8rem" }}
            />
            <span>{contactType2}: 
                  {FunctionalConfig.CustomerContactClickable ? (
                    <a
                      href={contactCopy2}
                      style={{ textDecoration: "underline", marginLeft: "0.5rem" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {contactNumber2}
                    </a>
                  ) : (
                    contactNumber2
                  )}</span>
            <CopyToClipboard
              text={contactCopy2}
              onCopy={() => {
                Toast.success({
                  message: "Copy Successfully",
                });
              }}
            >
              <img
                style={{ width: "1.7rem", height: "1.7rem", margin: "1.2rem 0 0 1.9rem" }}
                src={require("@/assets/icons/copy.png").default}
              />
            </CopyToClipboard>
          </div>
        )}

        <div class={style.footer_common}>
          <img src={require("@/assets/images/home/18_plus.png").default} />
          <span>
            <span class={style.symbol}>©</span> {LocalConfig.gameName} {langText("common_tips_1")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
