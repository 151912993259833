import React from "react";
import style from "./style.less";
import { LanguageContext } from "@/data/LanguageProvider";
import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { LocationInfo } from "@/data/LocationInfo";
import { LocalConfig } from "@/config";

const About = () => {
  const { langText } = React.useContext(LanguageContext);
  return (
    <div class={style.about_container}>
      <HomeHeaderOther title={langText("profile_btn_3")} />
      <div class={style.about_content}>
          <div class={style.about_content_title}>
              <img src={LocalConfig.icon} style={{ width: "2.6rem", height: "2.6rem"}} />
              <img src={LocalConfig.titleImgWhite} style={{ width: "7.9rem", marginLeft: " 0.555rem", marginTop: "0.3rem" }} />
          </div>
          <div class={style.about_content_intro}>
            <span>{langText("profile_tips_2", {param1:LocalConfig.gameName, param2:LocationInfo().name})}</span><br />
              <div class={style.about_content_no}>{LocalConfig.licence}</div>
          </div>
      </div>
        <div class={style.footer_common}>
            <img src={require("@/assets/images/home/18_plus.png").default} />
            <span><span class={style.symbol}>©</span> {LocalConfig.gameName} {langText("common_tips_1")}</span>
        </div>
    </div>
  );
};

export default About;
