/**
 * 本地化配置
 * 配置文字的本地化，包括语言、货币、图片的path等
 *
 */

export default {
  locationKey: "ZM",
  currencyCode: "ZMW",
  currencyName: "Zambian kwacha",
  gameName: "GameLaka",
  appVersion: "1.1.0",
  forceUpdateToVersion: "1.0.8",
  companyName: "LAKA",
  licence: "No.0000356",
  availableCountryList: ["ZM"],
  icon: require("@/assets/icon.png").default,
  appIcon: require("@/assets/app_icon.png").default,
  appIcon64: require("@/assets/app_icon_64x64.png").default,
  appIconRound: require("@/assets/icon_round.png").default,
  appIconRound64: require("@/assets/icon_round_64x64.png").default,
  titleImg: require("@/assets/images/header/fz_char_logo.png").default,
  titleImgWhite: require("@/assets/images/header/fz_char_logo_white.png").default,
  indexBannerHarvestImg: require("@/assets/images/home/index_banner_harvest_zm.png").default,
  indexBannerDepositImg: "index_banner_deposit_zm",
  indexBannerDeposit2Img: "index_banner_deposit2",
  indexBannerDrawImg: "index_banner_draw",
  indexBannerinviteImg: "index_banner_invite_zm",
  indexBannerLuckyWheelImg: "index_banner_lucky_wheel_zm",
  indexBannerInstallImg: "index_banner_install_zm",
  indexBannerFreeCashImg: "index_banner_freecash",
  referFriendsPopup: "ing_2",
  freeCashPopup: "popup_freecash",
  registerDownloadAd: "reg_download_zm",
  downloadFloatImg: require("@/assets/images/download/float-download-zm.png").default,
  luckyWheelShareImg: "/assets/images/lucky_wheel/banner_x2_zm.png",
  
};
